import styled from "styled-components"

const Heading = styled.h1`
font-family: Gotham-Black, sans serif;
font-size:22px;
color:white;
display:inline-block;
background-color:black;
display:inline-block;
padding:5px 12px;
margin:80px 0;
text-transform:uppercase;
border-radius:26px;
@media (min-width: 970px) {
    font-size:30px;
  }
`;

export default Heading;