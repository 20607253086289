import styled from "styled-components";

const Wrapper = styled.a`
text-decoration:none;
border:0;
display:flex;
align-items:cemnter;
cursor:pointer;
`;
const Title = styled.p`
display:inline-block;
color:white;
font-family: Gotham-Light, sans-serif;
font-size:9px;
margin-right:4px;
transition:color .3s;
&:hover{
    color:#afca0b;
}
`;

const BottomItem = (props)=>{
    return (
    <Wrapper href={props.src}>
        <Title>{props.title}</Title>
    </Wrapper>
    );
}

export default BottomItem;