import styled from "styled-components"

const Icon = styled.img`
margin-bottom:12px;
height:50px;
`;
const Text = styled.p`
text-align:center;
font-size:15px;
font-family:Gotham-Medium,sans-serif;
`;
const Wrapper = styled.div`
display:flex;
flex-direction:column;
align-items:center;
max-width:40%;
padding:20px;
`;

const InfoIcon = (props) => (
<Wrapper>
<Icon src={props.icon}/>
<Text>{props.number}</Text>
<Text>{props.text}</Text>
</Wrapper>
);

export default InfoIcon;