import styled from "styled-components";

const Button = styled.a`
  text-decoration: none;
  background-color: ${(props) => (props.outlined ? "none" : "#afca0b")};
  font-family: GothamRnd-Bold, sans-serif;
  padding: ${(props) => (props.small ? "4px 18px" : "15px 30px")};
  font-size: ${(props) => (props.small ? "10px" : "16px")};
  color: ${(props) => (props.outlined ? "#afca0b" : "white")};
  border: ${(props) => (props.outlined ? "3px solid #afca0b" : "none")};
  border-radius: 50px;
  margin: ${(props) => (props.small ? "0" : "40px 0")};
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
`;

export default Button;
