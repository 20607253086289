import styled from "styled-components";

const Wrapper = styled.a`
text-decoration:none;
border:0;
display:flex;
align-items:cemnter;
margin-bottom:25px;
cursor:pointer;
margin-bottom:35px;
`;
const Title = styled.p`
display:inline-block;
color:white;
font-family: Gotham-Light, sans-serif;
font-size:10px;
transition:color .3s;
&:hover{
    color:#afca0b;
}
@media (min-width: 1200px) {
font-size:14px;
    }
`;

const FooterItem = (props)=>{
    return (
    <Wrapper href={props.src}>
        <Title>{props.title}</Title>
    </Wrapper>
    );
}

export default FooterItem;