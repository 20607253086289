import styled from "styled-components"
import Button from "../Button/Button"
import Heading from "../Heading/Heading"
import burger from  "../../images/burger.svg"
import impreza from  "../../images/impreza.svg"
import truck from  "../../images/truck.svg"
import mapka from  "../../images/mapka.svg"
import InfoIcon from "../InfoIcon/InfoIcon"


const IconWrapper = styled.div`
display:flex;
justify-content:center;
flex-wrap:wrap;
`;

const Wrapper = styled.div`
display:flex;
flex-direction:column;
align-items:center;
width:100%;
`;


const SumUp2020 = () => (
    <Wrapper>
        <Heading>podsumowanie 2022</Heading>
        <IconWrapper>
            <InfoIcon icon={burger} number = "65 000" text="nakarmionych Pasibrzuchów"/>
            <InfoIcon icon={impreza} number = "120" text="odwiedzonych imprez"/>
            <InfoIcon icon={truck} number = "43 985" text="przejechanych kilometrów"/>
            <InfoIcon icon={mapka} number = "58" text="odwiedzone miasta"/>
        </IconWrapper>
        <Button outlined href="https://pasibus.pl/pl/kontakt/">zaproś nas na event!</Button>
    </Wrapper>
);

export default SumUp2020;