import styled from "styled-components"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
align-items: ${props => props.right ? "flex-end" : "flex-start"};
margin:25px;
transform-origin:top right;
@media (min-width: 720px) {
    transform:scale(1.3);
    
  }
  @media (min-width: 1180px) {
    transform:scale(1.5);
  }
  @media (min-width: 1500px) {
    transform:scale(2);
  }
`;

const Heading = styled.p`
font-family: Gotham-Black, sans serif;
font-size:32px;
color:white;
background-color:black;
display:inline-block;
padding:3px 12px;
text-transform:uppercase;
margin-bottom:-9px;

`;

const Claim = (props) => {
    return (
        props.right 
        ?<Wrapper right>
            <Heading>{props.line1}</Heading>
            <Heading>{props.line2}</Heading>
            <Heading>{props.line3}</Heading>
        </Wrapper>
        : <Wrapper>
            <Heading>{props.line1}</Heading>
            <Heading>{props.line2}</Heading>
            <Heading>{props.line3}</Heading>
        </Wrapper>
);
    };

export default Claim;