import { useState, useEffect } from "react";
import styled from 'styled-components';
import Button from "./components/Button/Button";
import Event from "./components/Event/Event";
import EventNotFound from "./components/EventNotFound/EventNotFound";
import EventsNotFound from "./components/EventsNotFound/EventsNotFound";
import Footer from "./components/Footer/Footer";
import Heading from "./components/Heading/Heading";
import Hero from "./components/Hero/Hero";
import Loader from "./components/Loader/Loader";
import NavbarMobile from "./components/NavbarMobile/NavbarMobile";
import SumUp2020 from "./components/SumUp2020/SumUp2020";
import Wrapper from "./components/Wrapper/Wrapper";
import GlobalStyle from "./GlobalStyle";
import "./index.css";
import StateProvider from "./StateProvider";
import MenuWrapper from "./components/MenuWrapper/MenuWrapper"

const EventWrapper = styled.div`
width:100%;
padding:0 25px;
@media (min-width: 870px) {
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
}

`;

const Select = styled.select`
margin-bottom:30px;
`;

const Spacer = styled.div`
height:70px;

`;

const App = () => {
  useEffect(() => {
    fetch("https://www.api2845.pasibus.pl/event/")
      .then((response) => response.json())
      .then((data) => setEvents(data));
  }, []);
  const [events, setEvents] = useState(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [filtered, setFiltered] = useState(null);

  const handleVoivodeship = (e) => {
    return setFiltered(
      events.filter((el) => el.voivodeship === e.target.value)
    );
  };

  const handleSearch = () => {
    return setSearchVisible(!searchVisible);
  };

  return (
    <StateProvider>
    <NavbarMobile/>
    <MenuWrapper/>
    <Spacer/>
      <Hero />
      <Wrapper>
        {searchVisible ? (
          <>
            <Heading>znajdź event</Heading>
            <Select onChange={handleVoivodeship}>
              <option default>Wybierz województwo:</option>
              <option value="DOLNOŚLĄSKIE">dolnośląskie</option>
              <option value="KUJAWSKO-POMORSKIE">kujawsko-pomorskie</option>
              <option value="LUBELSKIE">lubelskie</option>
              <option value="LUBUSKIE">lubuskie</option>
              <option value="ŁÓDZKIE">łódzkie</option>
              <option value="MAŁOPOLSKIE">małopolskie</option>
              <option value="MAZOWIECKIE">mazowieckie</option>
              <option value="OPOLSKIE">opolskie</option>
              <option value="PODKARPACKIE">podkarpackie</option>
              <option value="PODLASKIE">podlaskie</option>
              <option value="POMORSKIE">pomorskie</option>
              <option value="ŚLĄSKIE">śląskie</option>
              <option value="ŚWIĘTOKRZYSKIE">świętokrzyskie</option>
              <option value="WARMIŃSKO-MAZURSKIE">warmińsko-mazurskie</option>
              <option value="WIELKOPOLSKIE">wielkopolskie</option>
              <option value="ZACHODNIOPOMORSKIE">zachodniopomorskie</option>
            </Select>
            <EventWrapper>
            {filtered
              ? filtered.map((el) => <Event event={el} key={el.id} />)
              : null}
              </EventWrapper>
            {filtered ? filtered.length === 0 && <EventNotFound /> : null}
            <Button outlined onClick={handleSearch}>najbliższe eventy</Button>
          </>
        ) : (
          <>
            <Heading>nadchodzące eventy</Heading>
            <EventWrapper>
            {events
              ? events.slice(0, 5).map((el) => <Event event={el} key={el.id} />)
              : <Loader/>}
              {events ? events.length === 0 && <EventsNotFound /> : null}
              </EventWrapper>
              {events
              ? <Button outlined onClick={handleSearch}>
              znajdź event
            </Button>
              : null}
            
          </>
        )}
        
      </Wrapper>
      <SumUp2020 />
      <Footer/>
      <GlobalStyle />
    </StateProvider>
  );
};

export default App;
