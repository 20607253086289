import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: Gotham-Book, sans-serif;
    // border:1px solid red;
}
`;

export default GlobalStyle;