import styled from "styled-components";
import logo_outline from "../../images/logo_outline.svg";
import FooterItem from "./FooterItem";
import fb from "../../images/fb.svg";
import insta from "../../images/in.svg";
import yt from "../../images/yt.svg";
import BottomItem from "./BottomItem";

const Wrapper = styled.div`
width:100%;
background-color:black;
border-top:15px solid #afca0b;
display:flex;
flex-direction:column;
align-items:center;
padding:25px;
`;

const LogoFooter = styled.img`
width:80px;
margin-bottom:35px;
`;

const SocialLink = styled.a`
`;

const SocialIcon = styled.img`
width:25px;
cursor:pointer;
`;

const SocialWrapper = styled.div`
display:flex;
justify-content:space-around;
width:90%;
border-bottom:1px solid white;
padding-bottom:25px;
@media (min-width: 750px) {
width:60%;
}
@media (min-width: 1000px) {
    width:50%;
    padding:0 100px;
    padding-bottom:25px;
    }
@media (min-width: 1500px) {
        width:30%;
    }
`;


const CopyRight = styled.p`
display:inline-block;
color:white;
font-family: Gotham-Light, sans-serif;
font-size:12px;
margin 15px 0;
`;

const BottomMenu = styled.div`
display:flex;
`;

const MenuWrapper = styled.div`
display:flex;
flex-direction:column;
align-items:center;
width:90%;
@media (min-width: 1000px) {
    flex-direction:row;
    justify-content:space-between;
    }
    @media (min-width: 1200px) {
        width:80%;
            }
            @media (min-width: 1500px) {
                width:60%;
                    }
`;

const Footer = () => (
<Wrapper>
<LogoFooter src={logo_outline}/>
<MenuWrapper>
<FooterItem title="AKTUALNOŚCI" src="https://pasibus.pl/pl/aktualnosci/"/>
<FooterItem title="NASZE MENU" src="https://pasibus.pl/pl/menu/burgery/"/>
<FooterItem title="DOSTAWY" src="https://pasibus.pl/pl/dostawy/"/>
<FooterItem title="O NAS" src="https://pasibus.pl/pl/o-nas/"/>
<FooterItem title="LOKALIZACJE" src="https://pasibus.pl/pl/lokalizacje/"/>
<FooterItem title="EVENTY | CATERING" src="https://pasibus.pl/pl/eventy/"/>
<FooterItem title="PASI SKLEP" src="https://pasisklep.pl/"/>
<FooterItem title="KONTAKT" src="https://pasibus.pl/pl/kontakt/"/>
</MenuWrapper>
<SocialWrapper>
    <SocialLink href="https://pl-pl.facebook.com/Pasibus/" target="_blank">
        <SocialIcon src={fb}/>
    </SocialLink>
    <SocialLink href="https://www.instagram.com/pasibus/" target="_blank">
        <SocialIcon src={insta}/>
    </SocialLink>
    <SocialLink href="https://www.youtube.com/pasiwizja" target="_blank">
        <SocialIcon src={yt}/>
    </SocialLink>
</SocialWrapper>
<CopyRight>Copyright by PASIBUS | Made by PASIBUS | v 2.1.2</CopyRight>
<BottomMenu>
<BottomItem title="REGULAMINY |" src="https://pasibus.pl/pl/regulaminy/"/>
<BottomItem title="POLITYKA PRYWATNOŚCI |" src="https://pasibus.pl/pl/polityka-prywatnosci/"/>
<BottomItem title="NOTA PRAWNA" src="https://pasibus.pl/pl/nota-prawna/"/>
</BottomMenu>
</Wrapper>
);

export default Footer;