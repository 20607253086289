import styled from "styled-components";
import location from "../../images/location.svg";
import calendar from "../../images/calendar.svg";

const Wrapper = styled.div`
display:flex;
flex-direction:column;
width:100%;
// height:180px;
position:relative;
// align-items:center;
  border: 1px solid black;
  background-color: white;
  -webkit-box-shadow: 3px 3px 0px 0px #000000;
  box-shadow: 3px 3px 0px 0px #000000;
  margin: 10px 0;
  padding: 16px;
  padding-bottom: 30px;
  @media (min-width: 870px) {
    width:350px;
    height:200px;
    margin:10px 10px;
    padding:25px;
  }
`;

const Button = styled.a`
  text-decoration: none;
  background-color: ${(props) => (props.outlined ? "none" : "#afca0b")};
  font-family: GothamRnd-Bold, sans-serif;
  padding: ${(props) => (props.small ? "6px 20px" : "15px 30px")};
  font-size: ${(props) => (props.small ? "12px" : "16px")};
  color: ${(props) => (props.outlined ? "#afca0b" : "white")};
  border: ${(props) => (props.outlined ? "3px solid #afca0b" : "none")};
  border-radius: 50px;
  margin: ${(props) => (props.small ? "0" : "40px 0")};
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-top:-23px;
  margin-bottom:5px;
  position:absolute;
    bottom:10px;
    right:15px;
  @media (min-width: 870px) {
    position:absolute;
    bottom:10px;
    right:15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top:5px;
`;

// const RowRight = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content:flex-end;
//   margin-top:5px;
// `;

const EventTitle = styled.h2`
  display: inline-block;
  font-size: 16px;
  font-family: Gotham-Black;
  text-transform: uppercase;
  border-bottom: 3px solid #afca0b;
  padding-bottom: 2px;
  letter-spacing: -1px;
`;
const CityName = styled.p`
  font-size: 14px;
  font-family: Gotham-Black;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: -1px;
  margin-right: 7px;
  padding: 5px 0;
`;

const LocationName = styled.p`
  font-size: 14px;
  font-family: Gotham-Light;
  text-transform: lowercase;
  text-transform: capitalize;
  display: inline-block;
  // border-left: 1px solid black;
  // padding-left: 5px;
  padding-top:5px;
`;
const Date = styled.p`
  font-size: 14px;
  font-family: Gotham-Light;
  text-transform: lowercase;
  text-transform: capitalize;
  display: inline-block;
  padding-left: 5px;
`;

const Icon = styled.img`
  margin-right: 5px;
  height: 15px;
  width:16px;
`;

const Event = (props) => (
  <Wrapper>
      <EventTitle>{props.event.name}</EventTitle>
      <Row>
        <LocationName>{props.event.location}</LocationName>
        </Row>
      <Row>
        <Icon src={location} />
        <CityName>{props.event.city}</CityName>
      </Row>
      <Row>
        <Icon src={calendar} />
        <Date>{props.event.event_date}</Date>
        </Row>
        {props.event.url?<Button outlined small href={props.event.url} target="new">
        + więcej
      </Button> : null}
      
  </Wrapper>
);

export default Event;
