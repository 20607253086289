import styled from "styled-components"
import header_1 from "../../images/header_1.jpg"
import Claim from "../Claim/Claim";

const Background = styled.div`
height:350px;
background:linear-gradient(
    rgba(0, 0, 0, 0.4), 
    rgba(0, 0, 0, 0.4)
  ), url(${header_1});
background-size:120%;
background-position:center;
padding:10px;

@media (min-width: 460px) {
  background-size:100%;

  }

@media (min-width: 600px) {
background-position:center;
}

@media (min-width: 970px) {
  height:450px;
  background-size:130%;
  background-position:-250px;
}
@media (min-width: 1230px) {
  height:550px;
  background-position:-350px;
}
@media (min-width: 1555px) {
  height:650px;
  background-position:center;
  background-size:110%;
}
@media (min-width: 1700px) {
  background-size:100%;
  background-position:center;
}
`;

const Hero = (props) => (
<>
<Background>
<Claim line1="eventy" line2="z nami" line3="to radość!" right />
</Background>
</>
);

export default Hero;