import styled from "styled-components"
import { useContext } from "react";
import Context from '../../context';
import pasibus from "../../images/pasibus.svg"
import hamburger_menu from "../../images/hamburger_menu.svg"
import close_menu from "../../images/close_menu.svg"


const Wrapper = styled.div`
display:flex;
background-color:black;
height:70px;
display: flex;
align-items:center;
padding:0 25px;
width:100%;
position:fixed;
top:0;
left:0;
z-index:9999;
`;

const Logo = styled.img`
position:absolute;
left:25px;
width:120px;
@media (min-width:390px){
width:140px;
}
`;
const Hamburger = styled.img`
position:absolute;
right:25px;
cursor:pointer;
width:27px;
`;


const Button = styled.a`
  text-decoration: none;
  font-family: GothamRnd-Bold, sans-serif;
  background-color:rgba(175,202,11,0);
  padding:6px 10px;
  font-size: 10px;
  color: #afca0b;
  border: 3px solid #afca0b;
  border-radius: 50px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute;
  right:75px;
  transition: all .3s;
  &:hover{
    background-color:rgba(175,202,11,1);
    color:white;
  }
  @media (min-width:390px){
    padding:8px 12px;
    font-size: 12px;
    border: 3px solid #afca0b;
    border-radius: 50px;
  }
`;

const Link = styled.a`
display:flex;
width:150px;
height:27px;
align-items:center;
text-decoration:none;
cursor:pointer;
`;

const NavbarMobile = () => {
  const { state, dispatch } = useContext(Context);
return (
<Wrapper>
<Link href="https://pasibus.pl/">
<Logo src={pasibus} alt="pasibus logo"/>
</Link>
<Button onClick={(e) => {
      e.preventDefault();
      window.open("http://pasidostawa.pl", "_blank");
      }}>zamów online</Button>
      {
        state.menuActive
        ? <Hamburger src={close_menu} alt="menu button" onClick={() => dispatch("TOGGLE_MENU")}/>
        : <Hamburger src={hamburger_menu} alt="menu button" onClick={() => dispatch("TOGGLE_MENU")}/>
      }
</Wrapper>
);
}

export default NavbarMobile;