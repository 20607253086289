import styled from "styled-components"

const Wrapper = styled.div`
display:flex;
flex-direction:column;
align-items:center;
width:100%;
padding: 0 10px;
`;

export default Wrapper;