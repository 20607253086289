import styled from "styled-components";

const Wrapper = styled.a`
  text-decoration: none;
  border: 0;
  display: flex;
  align-items: cemnter;
  margin-bottom: 3vh;
  cursor: pointer;
  @media (min-width: 390px) {
    margin-bottom: 4vh;
  }
`;

const Icon = styled.img`
  height: 4vh;
  margin-right: 5px;
  @media (min-width: 390px) {
    height: 4vh;
    margin-right: 15px;
  }
  @media (min-height: 1080px) {
    height: 3vh;
    margin-right: 15px;
  }
`;
const IconWrapper = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
`;

const Title = styled.p`
  display: inline-block;
  color: white;
  font-family: Gotham-Black, sans-serif;
  font-size: 3vh;
  transition: color 0.3s;
  &:hover {
    color: #afca0b;
  }
  @media (min-width: 390px) {
    font-size: 3vh;
  }
  @media (min-height: 1080px) {
    font-size:2.5vh;
  }
`;

const MenuItem = (props) => {
  return (
    <Wrapper href={props.src}>
      <IconWrapper>
        <Icon src={props.icon} />
      </IconWrapper>
      <Title>{props.title}</Title>
    </Wrapper>
  );
};

export default MenuItem;