import styled from "styled-components";
import MenuItem from "./MenuItem.js";
import aktualnosci from "../../images/menu/aktualnosci.png";
import nasze_menu from "../../images/menu/nasze_menu.png";
import dostawy from "../../images/menu/dostawy.png";
import o_nas from "../../images/menu/o_nas.png";
import lokalizacje from "../../images/menu/lokalizacje.png";
import eventy from "../../images/menu/eventy.png";
import catering from "../../images/menu/catering.png";
import pasi_sklep from "../../images/menu/pasi_sklep.png";
import kontakt from "../../images/menu/kontakt.png";
import fb from "../../images/fb.svg";
import insta from "../../images/in.svg";
import yt from "../../images/yt.svg";

const Wrapper = styled.div`
  @keyframes appear-mobile {
    from {
      right: -100vw;
    }
    to {
      right: 0;
    }
  }
  @keyframes appear-desktop {
    from {
      right: -650px;
    }
    to {
      right: 0;
    }
  }
  animation-name: appear-mobile;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forward;
  position: fixed;
  top: 70px;
  right: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 1);
  height: 100vh;
  border-top: 2px solid #3c3c3c;
  color: white;
  padding: 4vh;
  padding-left: 4vh;
  z-index: 9999;
  @media (min-width: 390px) {
    height: 100vh;
    position: fixed;
  }
  @media (min-width: 650px) {
    width: 650px;
    animation-name: appear-desktop;
    background-color: rgba(0, 0, 0, 0.95);
  }
`;

const SocialLink = styled.a``;

const SocialIcon = styled.img`
  width: 4vh;
  cursor: pointer;
  @media (min-width: 390px) {
    width: 3vhpx;
  }
  @media (min-width: 650px) {
    width: 30px;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-around;
  border-top: 1px solid white;
  margin: 0 auto;
  padding: 0 30px;
  padding-top: 25px;
  margin-top: 35px;
  @media (min-width: 390px) {
    margin-top: 5vh;
  }
`;

const Menu = () => (
  <Wrapper>
    <MenuItem
      title="AKTUALNOŚCI"
      icon={aktualnosci}
      src="https://pasibus.pl/pl/aktualnosci/"
    />
    <MenuItem
      title="NASZE MENU"
      icon={nasze_menu}
      src="https://pasibus.pl/pl/menu/burgery/"
    />
    <MenuItem
      title="DOSTAWY"
      icon={dostawy}
      src="https://pasibus.pl/pl/dostawy/"
    />
    <MenuItem title="O NAS" icon={o_nas} src="https://pasibus.pl/pl/o-nas/" />
    <MenuItem
      title="LOKALIZACJE"
      icon={lokalizacje}
      src="https://pasibus.pl/pl/lokalizacje/"
    />
    <MenuItem
      title="EVENTY | CATERING"
      icon={eventy}
      src="https://pasibus.pl/pl/eventy/"
    />
    <MenuItem
      title="PASI SKLEP"
      icon={pasi_sklep}
      src="https://pasisklep.pl/"
    />
    <MenuItem
      title="KONTAKT"
      icon={kontakt}
      src="https://pasibus.pl/pl/kontakt/"
    />
    <SocialWrapper>
      <SocialLink href="https://pl-pl.facebook.com/Pasibus/" target="_blank">
        <SocialIcon src={fb} />
      </SocialLink>
      <SocialLink href="https://www.instagram.com/pasibus/" target="_blank">
        <SocialIcon src={insta} />
      </SocialLink>
      <SocialLink href="https://www.youtube.com/pasiwizja" target="_blank">
        <SocialIcon src={yt} />
      </SocialLink>
    </SocialWrapper>
  </Wrapper>
);

export default Menu;