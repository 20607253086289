import React, {useContext} from 'react';
import Context from '../../context'
import Menu from '../Menu/Menu'
import styled from 'styled-components';

const Wrapper = styled.div`

`;

const MenuWrapper = ()=> {
    const {state} = useContext(Context);
    return (
    <Wrapper>
        {
        state.menuActive
        ? <Menu/>
        : null
        }
    </Wrapper>
    );
}


export default MenuWrapper;
