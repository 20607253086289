import styled from "styled-components";
import event_not_found from "../../images/event_not_found.svg";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0;
  margin: 25px 0;
  @media (min-width: 870px) {
    width:450px;
  }
`;

const Paragraph = styled.p`
  font-family: ${(props) =>
    props.bold ? "Gotham-Black" : "Gotham-Book"};
  text-transform: uppercase;
  font-size: ${(props) => (props.bold ? "14px" : "12px")};
  color: ${(props) => (props.bold ? "#686868" : "#909090")};
  text-align: center;
  margin: 10px 35px;
`;

const Image = styled.img``;

const EventsNotFound = () => (
  <Wrapper>
    <Image src={event_not_found} />
    <Paragraph bold>pasibrzuchu!</Paragraph>
    <Paragraph>kolejne eventy w planach!</Paragraph>
    <Paragraph>śledź nas na bieżąco</Paragraph>
    <Paragraph>codziennie dodajemy nowe eventy!</Paragraph>
  </Wrapper>
);

export default EventsNotFound;
